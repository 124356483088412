<template>
  <b-container fluid class="member-page">
    <b-row>
      <b-col lg="5" cols="12" class="mx-auto">
        <BoxElement
          title="Commission"
          :value="Statistical.totalCommission || '$ 0'"
          class="pnl-card"
        >
          <template v-slot:icon><i class="fad fa-user-chart"></i></template>
          <template v-slot:content>
            <b-row class="box-info-content">
              <b-col cols="9" class="d-flex justify-content-center flex-column align-items-start">
                <div class="cash font-weight-bold" style="font-size: 19px">
                  {{ Statistical.totalTrade || '0 Lot' }}
                </div>
                <div class="title text-customer-1">Total Trading</div>
              </b-col>
              <b-col cols="3" v-if="false">
                <div class="box-level">
                  <img
                    :src="
                      Statistical ? Statistical.image : require('@/assets/images/logo/onlyicon.png')
                    "
                    alt=""
                    class="img-level"
                    width="100%"
                  />
                  <span class="level-name">{{ Statistical.name || '' }}</span>
                </div>
              </b-col>
            </b-row>
          </template>
        </BoxElement>
      </b-col>
      <b-col lg="5" cols="12" class="mx-auto">
        <BoxElement
          title="Referral Link"
          subtitle="member"
          :value="Member.member"
          class="Referral-card"
        >
          <template v-slot:icon>
            <i class="fad fa-users"></i>
          </template>
          <template v-slot:content>
            <div class="boxref mb-1">
              <span>Link Ref</span>
              <div class="ref-link">
                <b-input-group class="">
                  <template #prepend>
                    <b-input-group-text>
                      <b-button
                        variant="secondary"
                        v-clipboard:copy="url"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError"
                        >Copy</b-button
                      >
                    </b-input-group-text>
                  </template>
                  <b-form-input type="text" required v-model="url" readonly></b-form-input>
                </b-input-group>
              </div>
            </div>
          </template>
        </BoxElement>
      </b-col>
    </b-row>
    <b-row class="mt-4 w-100 ml-0">
      <b-col cols="12">
        <div class="box-member">
          <div class="title">Member List</div>
          <div class="box-table">
            <div class="table-search">
              <b-form-input
                size="sm m-1"
                placeholder="User ID"
                v-model.trim="search.user_id"
              ></b-form-input>
              <b-form-input
                size="sm m-1"
                placeholder="Email"
                v-model.trim="search.email"
              ></b-form-input>
              <b-form-input
                size="sm m-1"
                placeholder="F.."
                v-model.trim="search.user_f"
              ></b-form-input>
              <b-button variant="outline-success" class="btn-egg btn-sm m-1" @click="onSearch">
                Search
              </b-button>
              <b-button variant="secondary" class="btn-sm m-1" @click="onSearch('reset')">
                Reset
              </b-button>
            </div>
            <div class="table-reponsive">
              <b-table striped hover :items="Member.list" :fields="fields">
                <template #cell(f)="data"> Level {{ data.item.f }} </template>
              </b-table>
            </div>
            <div class="table-paginate">
              <Paginate @current="onChangePage" :totalPages="Member.total" />
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';

import BoxElement from '@/components/element/Box.vue';
import Paginate from '@/components/shared/Paginate.vue';

export default {
  components: { BoxElement, Paginate },
  data() {
    return {
      search: {
        user_id: '',
        email: '',
        user_f: '',
        page: 1,
      },
      fields: [
        {
          key: 'userId',
          sortable: false,
          label: 'User ID',
        },
        {
          key: 'email',
          sortable: false,
          label: 'User Email',
        },
        {
          key: 'f',
          label: 'Ref',
          sortable: false,
        },
        {
          key: 'totalDeposit',
          label: 'Total Deposit',
          sortable: false,
        },
        {
          key: 'totalWithdraw',
          label: 'Total Withdraw',
          sortable: false,
        },
        {
          key: 'totalCommission',
          label: 'Total Commission',
          sortable: false,
        },
        // {
        //   key: 'totalTrade',
        //   label: 'Total Trade',
        //   sortable: false,
        // },
        {
          key: 'volumeTrade',
          label: 'Volume Trade',
          sortable: false,
        },
        {
          key: 'balance',
          label: 'Balance',
          sortable: false,
        },
        {
          key: 'equity',
          label: 'Equity',
          sortable: false,
        },
        {
          key: 'profit',
          label: 'Profit',
          sortable: false,
        },
        // {
        //   key: 'netDepositGroup',
        //   label: 'Net Deposit Group',
        //   sortable: false,
        // },
        {
          key: 'registeredDatetime',
          label: 'Registration Date',
        },
      ],
      currentPage: 1,
      totalPages: 10,
    };
  },
  computed: {
    ...mapGetters({
      UserInfo: 'user/UserInfo',
      Member: 'member/Member',
      Statistical: 'user/Statistical',
    }),
    url() {
      return `${window.location.hostname}/secure/register?sponsor=${this.UserInfo.id}`;
    },
  },
  methods: {
    onCopy() {
      this.$toastr.s('Copy Link Referral Success', 'Successfully');
    },
    onError() {
      this.$toastr.e('Copy Link Referral Fail,Please Try Again', 'Failed');
    },
    onSearch(type) {
      if (type === 'reset') {
        this.search = {
          user_id: '',
          email: '',
          user_f: '',
          page: 1,
        };
      }
      this.$store.dispatch('member/req_getListMember', this.search);
    },
    onChangePage(payload) {
      this.search.page = payload;
      this.$store.dispatch('member/req_getListMember', this.search);
    },
  },
  created() {
    this.$store.dispatch('member/req_getListMember', this.search);
  },
};
</script>

<style lang="scss">
.member-page {
  .Referral-card {
    .ref-id {
      h4 {
        margin-bottom: 8px;
        text-transform: capitalize;
        letter-spacing: 0.02em;
        font-size: 18px;
        font-weight: 600;
        margin-top: 0;
        color: #6c757d;
        text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
        span {
          font-size: 22px;
          color: #2f394e;
          font-weight: 600;
        }
      }
    }
    .boxref {
      width: 100%;
      span {
        font-size: 17px;
        color: #6c757d;
        font-weight: 600;
      }
      .ref-link {
        margin-top: 5px;
        background: #ff5c00;
        border-radius: 5px;
        .input-group-text {
          padding: 0;
          border: 0;
          button {
            width: 70px;
            height: 40px;
            padding: 0;
            // background: #25485859 !important;
            border: 0;
            outline: none;
            border-radius: 5px;
            box-shadow: 0px 0px 5px 1px #00000042;
            font-weight: 600;
          }
        }
        input {
          height: 40px;
          border: 0;
          background: transparent;
          color: #fff;
          font-weight: 600;
          &:hover,
          &:focus,
          &:focus-visible {
            outline: none;
            box-shadow: none;
            border: 0;
          }
        }
      }
    }
  }
  .balance-card,
  .pnl-card {
    .box-info-content {
      width: 100%;
      height: 100%;
      align-items: center;
      .box-right,
      .box-left {
        flex: 0 0 50%;
        max-width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        .cash {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #2f394e !important;
          font-weight: 600;
          font-size: clamp(16px, 2vw, 20px);
        }
        .title {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #6c757d;
          margin-left: 5px;
          font-weight: 600;
          font-size: clamp(15px, 2vw, 17px);
          text-transform: capitalize;
        }
      }
      .box-left {
      }
      .box-right {
      }
      span.level-name {
        margin-bottom: 0px;
        text-transform: uppercase;
        letter-spacing: 0.02em;
        font-size: 15px;
        font-weight: 700;
        margin-top: 0;
        color: #6c757d;
        text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
      }
      .img-level {
        width: 75px;
        height: 75px;
        padding: 0px;
        background: transparent;
        box-shadow: none;
        filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.5));
        // border: 1px solid #34a849;
      }
    }
  }
  .box-member {
    margin-bottom: 20px;
    border: none;
    -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
    border-radius: 3px;
    background-color: #fff;
    min-height: 200px;
    width: 100%;
    .title {
      font-weight: 600;
      font-size: 20px;
      color: #2f394e;
      text-align: left;
      padding: 0 0 5px;
      text-transform: uppercase;
      width: 100%;
      padding: 5px 1.25rem;
    }
    .table-search {
      font-weight: 600;
      font-size: 20px;
      color: #2f394e;
      text-align: left;
      padding: 0 0 5px;
      border-top: 3px solid rgba($color: #000000, $alpha: 0.08);
      border-bottom: 3px solid rgba($color: #000000, $alpha: 0.08);
      text-transform: uppercase;
      width: 100%;
      min-height: 50px;
      padding: 5px 1.25rem;
      display: flex;
      flex-wrap: wrap;
      input {
        max-width: 225px;
      }
      select,
      input {
        border: 0;
        border-radius: 0;
        border-bottom: 2px solid #6c757d;
        height: 40px;
        font-size: clamp(15px, 1vw, 16.2px);
        font-weight: 600;
        position: relative;
        &:focus {
          box-shadow: none;
          border-bottom: 2px solid #097501;
        }
        &::placeholder {
          font-weight: 500;
          font-size: clamp(13px, 1vw, 15px);
        }
      }
      button {
        &.btn-egg {
          min-width: 105px;
          height: 40px;
        }
        width: auto;
        min-width: unset;
        font-weight: 600;
      }
    }
    .table-reponsive {
      width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      padding: 10px 5px;
      table {
        width: 100%;
        border-radius: 10px;
        th,
        td {
          min-width: 100px;
          white-space: pre;
          padding: 5px 0.75rem;
        }
        thead {
          background: #ff5c00;
          border-radius: 10px;
          box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1), 0 1px 0 0 rgba(0, 0, 0, 0.1);
          border: 0;
          color: #fff;
          font-weight: 600;
          tr {
            border-radius: 10px;
            th {
              border: 0;
              &:last-child {
                border-radius: 0px 10px 10px 0px;
              }
              &:first-child {
                border-radius: 10px 0 0 10px;
              }
            }
          }
        }
        tbody:before {
          content: '@';
          display: block;
          line-height: 10px;
          text-indent: -99999px;
        }
      }
    }
    .table-paginate {
      border-top: 3px solid rgba($color: #000000, $alpha: 0.08);
    }
  }
}
</style>
